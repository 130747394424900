import React, { useState, useEffect, FunctionComponent, ReactNode } from 'react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

const ld_client_id = window.__env__.LAUNCH_DARKLY_CLIENT_ID || '';

interface LaunchDarklyProviderProps {
    children: ReactNode;
}

const LaunchDarklyProvider: FunctionComponent<LaunchDarklyProviderProps> = ({ children }) => {
    const [isReady, setIsReady] = useState<boolean>(false);
    const [ProviderComponent, setProviderComponent] = useState<FunctionComponent<{ children: ReactNode }> | null>(null);

    useEffect(() => {
        asyncWithLDProvider({
            clientSideID: ld_client_id,
        }).then((Provider: FunctionComponent) => {
            setProviderComponent(() => Provider);
            setIsReady(true);
        });
    }, []);

    if (!isReady) {
        return <Loading />;
    }

    return ProviderComponent ? <ProviderComponent>{children}</ProviderComponent> : <Loading />;
};

export default LaunchDarklyProvider;

const Loading = () => (
    <div className="flex h-screen">
        <div className="m-auto">
            <p className="text-gray-500 text-xs">Loading Features ...</p>
        </div>
    </div>
);
