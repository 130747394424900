import { ApolloClientContext } from 'Utilities/AuthorizedApolloProvider';
import { useContext, useMemo, useState } from 'react';
import { useClipboard } from 'use-clipboard-copy';

const Commands: DeveloperCommand[] = [
    {
        name: 'Blank GQ Command',
        query: '',
        variables: {},
    },
    {
        name: 'Deploy Infra',
        query: 'mutation deployInfra($tenantId: ID!) { deployInfra(tenantId: $tenantId) }',
        variables: {
            tenantId: '',
        },
    },
    {
        name: 'Deploy Schema',
        query: 'mutation deploySchema($tenantId: ID!) { deploySchema(tenantId: $tenantId, cassandraSchema: false, mongoSchema: false, elasticSchema:true) }',
        variables: {
            tenantId: '',
        },
    },
    {
        name: 'Get Tenant',
        query: `query getTenant($tenantId: ID!) {
                    getTenant(tenantId: $tenantId) {
                        tenantId
                        name
                    }
                }`,
        variables: {
            tenantId: '',
        },
    },
];

// Define a type for the shells
type ShellType = 'bash' | 'fish' | 'zsh';

// Define the Shells constant
const Shells: readonly ShellType[] = ['bash', 'fish', 'zsh'];

// Definte a type for utilities
type UtilityType = 'cURL' | 'GQ';

// Define the Utilities constant
const Utilities: readonly UtilityType[] = ['cURL', 'GQ'];

export const Footer = (): JSX.Element => {
    const [currentShell, setCurrentShell] = useState<ShellType>('bash');
    const [currentUtility, setCurrentUtility] = useState<UtilityType>('GQ');

    return (
        <footer className="text-gray-400 bg-gray-800 body-font fixed bottom-0 w-full">
            <div className="mx-auto flex flex-wrap p-4 flex-col md:flex-row items-center text-sm">
                <div className="flex items-center justify-between w-full">
                    <div className="flex">
                        <div className="uppercase text-xs tracking-widest font-bold text-gray-500">Developer</div>

                        <div className="flex text-center">
                            {Commands.map((command, index) => (
                                <div
                                    key={index}
                                    className={`flex-grow ${
                                        index < Commands.length - 1 ? 'border-r border-gray-300 px-4' : 'px-4'
                                    }`}
                                >
                                    <DeveloperCommand {...command} shell={currentShell} utility={currentUtility} />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex space-x-4">
                        <div className="flex text-xs">
                            [
                            {Shells.map((shell, index) => (
                                <div
                                    key={index}
                                    className={
                                        `cursor-pointer hover:text-gray-200 flex-grow px-2 ${
                                            shell === currentShell
                                                ? 'text-gray-200 underline underline-offset-2'
                                                : 'text-gray-400'
                                        }` + (index < Shells.length - 1 ? ' border-r border-gray-400' : '')
                                    }
                                    onClick={() => setCurrentShell(shell)}
                                >
                                    {shell}
                                </div>
                            ))}
                            ]
                        </div>
                        <div className="flex text-xs">
                            [
                            {Utilities.map((utility, index) => (
                                <div
                                    key={index}
                                    className={
                                        `cursor-pointer hover:text-gray-200 flex-grow px-2 ${
                                            utility === currentUtility
                                                ? 'text-gray-200 underline underline-offset-2'
                                                : 'text-gray-400'
                                        }` + (index < Utilities.length - 1 ? ' border-r border-gray-400' : '')
                                    }
                                    onClick={() => setCurrentUtility(utility)}
                                >
                                    {utility}
                                </div>
                            ))}
                            ]
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

type DeveloperCommand = {
    name: string;
    query?: string;
    variables?: object;
    shell?: ShellType;
    utility?: UtilityType;
};

const DeveloperCommand = ({ name, query, variables, shell, utility }: DeveloperCommand): JSX.Element => {
    const { graphqlUri } = useContext(ApolloClientContext);

    const clipboard = useClipboard({
        copiedTimeout: 2000,
    });

    const accessToken = localStorage.getItem('accessToken');

    const { fullCommand } = useMemo(() => {
        let fullCommand = '';

        switch (utility) {
            case 'cURL':
                const data = {
                    query: query || '',
                    variables: variables || {},
                };
                switch (shell) {
                    case 'bash':
                    case 'zsh':
                        fullCommand =
                            `curl ${graphqlUri} \\\n` +
                            `-X POST \\\n` +
                            `-H "Content-Type: application/json" \\\n` +
                            `-H "Authorization: Bearer ${accessToken}" \\\n` +
                            `--data-binary @- <<'EOF'\n` +
                            JSON.stringify(data, null, 2) +
                            '\nEOF';
                        break;
                    case 'fish':
                        const dataStringified = JSON.stringify({
                            query: query,
                            variables: variables,
                        });

                        // Construct the curl command
                        fullCommand =
                            `echo '${dataStringified}' | \\\n` +
                            `curl ${graphqlUri} \\\n` +
                            `-X POST \\\n` +
                            `-H "Content-Type: application/json" \\\n` +
                            `-H "Authorization: Bearer ${accessToken}" \\\n` +
                            `--data-binary @-`;
                        break;
                }
                break;

            case 'GQ':
                const stringfiedVariables = JSON.stringify(variables, null, 2);
                const stringfiedQuery = query || '';
                let queryArg = '';
                let jsonArg = '';

                switch (shell) {
                    case 'bash':
                    case 'zsh':
                    case 'fish':
                        queryArg = `-q '${stringfiedQuery}'`;
                        jsonArg = `-j '${stringfiedVariables}'`;
                        break;

                    default:
                        break;
                }
                fullCommand =
                    `gq ${graphqlUri} \\\n` +
                    `-H "Content-Type: application/json" \\\n` +
                    `-H "Authorization: Bearer ${accessToken}" \\\n` +
                    queryArg +
                    ' \\\n' +
                    jsonArg;
        }

        return {
            fullCommand,
        };
    }, [variables, query, utility, shell, graphqlUri, accessToken]);

    return (
        <div
            className="text-gray-400 text-xs cursor-pointer hover:text-gray-200"
            onClick={() => clipboard.copy(fullCommand)}
        >
            {clipboard.copied ? 'Command Copied 👍' : name}
        </div>
    );
};
