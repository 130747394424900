import { useQuery, useApolloClient, useMutation } from '@apollo/client';
import {
    CheckCircleIcon,
    DotsCircleHorizontalIcon,
    ExclamationCircleIcon,
    PlusIcon,
    QuestionMarkCircleIcon,
    DotsHorizontalIcon,
} from '@heroicons/react/solid';
import { GET_PROVIDER_FIELD_MAP, LIST_PROVIDERS, DELETE_PROVIDER } from 'Graph/queries';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DynamicProvider, Provider } from 'Types/types';
import { formatBytes } from 'Utilities/utils';
import { CreateProvider } from './CreateProvider';

const providersFieldMap: Record<string, string> = {};

export const Providers = (): JSX.Element => {
    const { tenantId } = useParams<{ tenantId: string }>();
    const { loading, error, data } = useQuery(LIST_PROVIDERS, { variables: { tenantId }, pollInterval: 60000 });
    const { data: providerFieldMapData } = useQuery(GET_PROVIDER_FIELD_MAP, { variables: { tenantId } });
    const [isModalOpen, setIsModalOpen] = useState(false);

    const client = useApolloClient();

    const [deleteProvider] = useMutation(DELETE_PROVIDER);

    const deleteSelf = async (providerId: string) => {
        try {
            await deleteProvider({ variables: { tenantId, providerId } });
            const providers = client.readQuery({ query: LIST_PROVIDERS, variables: { tenantId } });

            const newProviders = providers.listProviders.filter(
                (provider: Provider) => provider.providerId !== providerId,
            );
            client.writeQuery({
                query: LIST_PROVIDERS,
                variables: { tenantId },
                data: { listProviders: newProviders },
            });
        } catch (error) {
            console.log('Error deleting provider');
        }
    };

    useEffect(() => {
        if (providerFieldMapData) {
            const { getProviderFieldMap } = providerFieldMapData;
            getProviderFieldMap.map((provider: DynamicProvider) => {
                providersFieldMap[provider.Name] = provider.DisplayName;
            });
        }
    }, [providerFieldMapData]);

    const getDisplayName = (name: string) => {
        return providersFieldMap[name] || name;
    };

    let content = <></>;

    if (loading) {
        content = (
            <div className="text-sm text-gray-500 justify-center flex items-center p-3">
                <div className="loader mr-2" />
                Loading Providers...
            </div>
        );
    } else if (error) {
        content = <h4 className="text-xs text-red-500">Could not load providers. Please retry later.</h4>;
    } else if (data) {
        const providers: Provider[] = data.listProviders;
        const sortedProviders = providers
            .slice()
            .sort((a, b) => `${a.type}${a.name}`.localeCompare(`${b.type}${b.name}`));
        if (sortedProviders.length === 0) {
            content = (
                <>
                    <div className="flex items-center justify-between mb-5 h-[32px]">
                        <span className="text-sm">No providers</span>
                        <button
                            className="btn-primary inline-flex py-2 px-4 text-white rounded-md text-xs"
                            onClick={() => setIsModalOpen(true)}
                        >
                            <PlusIcon className="w-4 h-4 mr-2" /> Create New Provider
                        </button>
                    </div>
                    <h4 className="text-sm text-center mt-8 text-gray-500">
                        This tenant does not have any providers enabled.
                    </h4>
                </>
            );
        } else {
            content = (
                <>
                    <div className="flex items-center justify-between mb-5 h-[32px]">
                        <span className="text-sm">Viewing {data.listProviders.length} providers</span>
                        <button
                            className="btn-primary inline-flex py-2 px-4 text-white rounded-md text-xs"
                            onClick={() => setIsModalOpen(true)}
                        >
                            <PlusIcon className="w-4 h-4 mr-2" /> Create New Provider
                        </button>
                    </div>
                    <div className="-my-2 sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="shadow rounded-lg">
                                <table className="min-w-full divide-y divide-gray-700">
                                    <thead>
                                        <tr className="text-sm">
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider bg-gray-700"
                                                align="left"
                                            >
                                                Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider bg-gray-700"
                                                align="left"
                                            >
                                                Provider
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider bg-gray-700"
                                                align="left"
                                            >
                                                UUID
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider bg-gray-700"
                                                align="left"
                                            >
                                                Storage
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider bg-gray-700"
                                                align="left"
                                            >
                                                First Event
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider bg-gray-700"
                                                align="left"
                                            >
                                                Last Event
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider bg-gray-700"
                                                align="left"
                                            >
                                                Status
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider bg-gray-700"
                                                align="right"
                                            ></th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-gray-800 divide-y divide-gray-700">
                                        {sortedProviders.map((provider: Provider, index) => (
                                            <tr key={index} className="relative">
                                                <td
                                                    scope="col"
                                                    className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"
                                                    align="left"
                                                >
                                                    {provider.name || getDisplayName(provider.type)}
                                                </td>
                                                <td
                                                    scope="col"
                                                    className="px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-400"
                                                    align="left"
                                                >
                                                    {provider.type}
                                                </td>
                                                <td
                                                    scope="col"
                                                    className="px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-400"
                                                    align="left"
                                                >
                                                    {provider.providerId}
                                                </td>
                                                <td
                                                    scope="col"
                                                    className="px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-400"
                                                    align="left"
                                                >
                                                    {formatBytes(provider.storageUsed || 0)}
                                                </td>
                                                <td
                                                    scope="col"
                                                    className="px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-400"
                                                    align="left"
                                                >
                                                    {provider.firstEventAt &&
                                                        new Date(provider.firstEventAt / 1000000).toLocaleString()}
                                                </td>
                                                <td
                                                    scope="col"
                                                    className="px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-400"
                                                    align="left"
                                                >
                                                    {provider.lastEventAt &&
                                                        new Date(provider.lastEventAt / 1000000).toLocaleString()}
                                                </td>
                                                <td
                                                    scope="col"
                                                    className="px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-400"
                                                    align="left"
                                                >
                                                    <div className="flex items-center justify-start">
                                                        <ProviderDisplayIcon provider={provider} />
                                                        <span className="mx-2 lowercase first-letter:capitalize first-line:capitalize">
                                                            {statusLookup(provider.status)}
                                                        </span>

                                                        {provider.status == 'CONFIG_ERROR' &&
                                                            provider.lastError &&
                                                            provider.lastErrorAt && (
                                                                <ErrorDropdown
                                                                    lastError={provider.lastError}
                                                                    errorAt={provider.lastErrorAt}
                                                                />
                                                            )}
                                                    </div>
                                                </td>
                                                <td
                                                    scope="col"
                                                    className="px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-400"
                                                    align="right"
                                                >
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            deleteSelf(provider.providerId);
                                                            console.log(provider.providerId);
                                                        }}
                                                    >
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
    }

    return (
        <>
            {content}
            {isModalOpen && <CreateProvider setIsModalOpen={setIsModalOpen} />}
        </>
    );
};

const ProviderDisplayIcon = ({ provider }: { provider: Provider }) => {
    const { status } = provider;

    switch (status) {
        case 'ACTIVE':
            return <CheckCircleIcon className="h-4 text-green-600" />;
        case 'READY':
            return <DotsCircleHorizontalIcon className="h-4 text-blue-600" />;
        case 'ERROR':
            return <ExclamationCircleIcon className="h-4 text-yellow-500" />;
        case 'CONFIG_ERROR':
            return <ExclamationCircleIcon className="h-4 text-yellow-500" />;
        case 'UNKNOWN':
            return <QuestionMarkCircleIcon className="h-4 text-blue-600" />;
        default:
            break;
    }

    return <CheckCircleIcon className="h-5 text-blue-600" />;
};

interface ErrorDropdownProps {
    lastError: string;
    errorAt: number;
}

const ErrorDropdown = ({ lastError, errorAt }: ErrorDropdownProps): JSX.Element => {
    const [open, setOpen] = useState(false);
    //Multiply by 1000 to account for epoch time
    const errorTime = new Date(errorAt * 1000).toLocaleString();
    return (
        <div className="relative inline-block text-left">
            <button
                type="button"
                onClick={() => {
                    setOpen(!open);
                }}
                className="inline-flex justify-center w-full rounded-full shadow-sm p-2 bg-gray-800 text-sm font-medium text-gray-400 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-blue-400"
            >
                <DotsHorizontalIcon className="h-4 w-4" aria-hidden="true" />
            </button>
            {open && (
                <div className="origin-top-right absolute right-0 mt-2 w-[calc(600px] focus:outline-none pb-16">
                    <div className="rounded-md drop-shadow-[0_15px_35px_rgba(0,0,0,0.50)] bg-gray-700">
                        <div className="text-xs text-red-500 p-3 pb-0 whitespace-normal break-words font-mono relative z-10">
                            {lastError}
                        </div>
                        <div className="text-xs p-3 text-gray-500">{'Last occurred at: ' + errorTime}</div>
                    </div>
                </div>
            )}
        </div>
    );
};

const statusNames: Record<string, string> = {
    ACTIVE: 'active',
    READY: 'ready',
    ERROR: 'error',
    CONFIG_ERROR: 'config error',
    UNKNOWN: 'unknown',
};

export const statusLookup = (status: string) => {
    return statusNames[status] || 'Unknown';
};
