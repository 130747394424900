import { useQuery } from '@apollo/client';
import React, { Dispatch, SetStateAction } from 'react';
import { ChevronRightIcon, CheckIcon, XIcon, TrashIcon } from '@heroicons/react/solid';
import { useHistory } from 'react-router-dom';
import { LIST_TENANTS } from '../../../Graph/queries';

interface FormProps {
    isOpen: Dispatch<SetStateAction<boolean>>;
}

export type Tenant = {
    tenantId: string;
    name: string;
    cloud: string;
    region: string;
    slug: string;
    // Count number of Users
    userCount: number;
    // NEW
    status: number;
};

export const CustomerTenantsModal = ({ isOpen }: FormProps): JSX.Element => {
    const history = useHistory();
    const { loading, error, data } = useQuery(LIST_TENANTS);

    let content = <></>;

    if (loading) {
        content = (
            <tr className="border-gray-800 border-4 bg-gray-900 h-14">
                <td colSpan={5} className="text-sm text-gray-500 text-center">
                    Loading Tenants...
                </td>
            </tr>
        );
    } else if (error) {
        content = (
            <tr className="border-gray-800 border-4 bg-gray-900 h-14">
                <td colSpan={5} className="text-sm text-red-500 text-center">
                    Could not load Tenants. Please retry later.
                </td>
            </tr>
        );
    } else if (data) {
        content = data.listTenants.map((tenant: Tenant) => (
            <tr
                key={tenant.tenantId}
                className="border-gray-800 border-4 bg-gray-900 h-14 hover:bg-gray-700 cursor-pointer"
                onClick={() => history.push(`/tenants/${tenant.tenantId}`)}
            >
                <td className="p-2 pl-4" align="left">
                    {tenant.name}
                </td>
                <td className="p-2" align="left">
                    {tenant.slug}
                </td>
                <td className="p-2" align="center">
                    {tenant.cloud}
                </td>
                <td className="p-2" align="center">
                    {tenant.region}
                </td>
                <td className="p-2" align="right">
                    <div className="flex justify-end">
                        {tenant.status &&
                            {
                                1: (
                                    <button className="btn ml-2 w-28">
                                        <TrashIcon className="w-4 mr-2" />
                                        <span className="text-sm">Delete</span>
                                    </button>
                                ),
                                2: (
                                    <>
                                        <button className="btn ml-2 w-28">
                                            <CheckIcon className="w-4 mr-2" />
                                            <span className="text-sm">Approve</span>
                                        </button>
                                        <button className="btn ml-2 w-28">
                                            <XIcon className="w-4 mr-2" />
                                            <span className="text-sm">Reject</span>
                                        </button>
                                    </>
                                ),
                            }[tenant.status]}
                        View Tenant
                        <ChevronRightIcon className="ml-2 w-6" />
                    </div>
                </td>
            </tr>
        ));
    }

    return (
        <>
            <div
                className="fixed z-10 inset-0 overflow-hidden"
                aria-labelledby="modal-title"
                role="dialog"
                aria-modal="true"
            >
                <div className="items-end justify-center min-h-screen p-5 text-center block">
                    <div
                        className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity flex items-center justify-center"
                        aria-hidden="true"
                    >
                        <div
                            className="inline-block bg-gray-800 rounded-md text-left overflow-hidden shadow-xl transform 
                               transition-all align-middle w-9/12 max-w-7xl"
                        >
                            <div className="text-white p-5">View Tenants</div>
                            <div className="px-3 overflow-auto">
                                <table className="table-auto w-full text-gray-400">
                                    <thead>
                                        <tr className="text-sm">
                                            <th className="p-2" align="left">
                                                Tenant
                                            </th>
                                            <th className="p-2" align="left">
                                                Display Name
                                            </th>
                                            <th className="p-2">Cloud</th>
                                            <th className="p-2">Region</th>
                                            <th className="p-2">Status</th>
                                            <th className="p-2">Users</th>
                                            <th className="p-2" align="right">
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>{content}</tbody>
                                </table>
                            </div>
                            <div className="p-3 flex justify-end">
                                <button className="btn inline-flex rounded-md ml-2" onClick={() => isOpen(false)}>
                                    <span className="text-sm">Close</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <span className="inline-block align-middle h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                </div>
            </div>
        </>
    );
};
