import { useQuery } from '@apollo/client';
import { GET_PROVIDER_FIELD_MAP } from 'Graph/queries';
import { DynamicProvider, Provider } from 'Types/types';
import { CREATE_PROVIDER, LIST_PROVIDERS } from 'Graph/queries';
import { useMutation } from '@apollo/client';
import { useState, Fragment, Dispatch, SetStateAction } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Transition, Dialog } from '@headlessui/react';
import { useClipboard } from 'use-clipboard-copy';
import { useParams } from 'react-router-dom';

interface FormProps {
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const CreateProvider = ({ setIsModalOpen }: FormProps): JSX.Element => {
    const [requestedNewProvider, setRequestedNewProvider] = useState<DynamicProvider>();
    return (
        <>
            <Transition.Root show={true} as={Fragment}>
                <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => setIsModalOpen(false)}>
                    <div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">
                        <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <div className="relative inline-block align-middle text-left shadow-xl transform transition-all w-1/3">
                            <div className="relative rounded-lg bg-gray-800 p-5">
                                <h2 className="text-md text-gray-300 font-medium mb-5">Create a New Provider</h2>
                                {!requestedNewProvider ? (
                                    <div className="mt-5 grid grid-cols-4 gap-3 place-items-center text-gray-100 text-xs">
                                        <AvailableDynamicProviders handleClick={setRequestedNewProvider} />
                                    </div>
                                ) : (
                                    <NewDynamicProvider
                                        provider={requestedNewProvider}
                                        onClose={() => setRequestedNewProvider(undefined)}
                                        setIsModalOpen={setIsModalOpen}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};

export const AvailableDynamicProviders = ({
    handleClick,
}: {
    handleClick: (provider: DynamicProvider) => void;
}): JSX.Element => {
    const { tenantId } = useParams<{ tenantId: string }>();
    const { loading, error, data } = useQuery(GET_PROVIDER_FIELD_MAP, { variables: { tenantId } });
    const { availableProviders } = useFlags();

    let content = <></>;

    if (loading) {
        content = <h4 className="text-xs text-gray-300">Loading Providers...</h4>;
    } else if (error) {
        content = <h4 className="text-xs text-red-500">Could not load providers. Please retry later.</h4>;
    } else if (data) {
        const providers: DynamicProvider[] = data.getProviderFieldMap;

        // filter out providers that are not available using the Launch Darkly flag
        // if the flag is not set, then show all providers
        let filteredProviders: DynamicProvider[] = [];
        if (availableProviders) {
            filteredProviders = providers.filter((provider) => availableProviders.includes(provider.Name));
        } else {
            filteredProviders = providers;
        }

        // sort the providers alphabetically
        const sortedProviders = filteredProviders.slice().sort((a, b) => a.Name.localeCompare(b.Name));

        content = (
            <>
                {sortedProviders.map((provider) => {
                    return (
                        <button
                            className="btn w-24 h-12 capitalize"
                            key={provider.Name}
                            onClick={() => handleClick(provider)}
                        >
                            {provider.DisplayName}
                        </button>
                    );
                })}{' '}
                <button className="btn w-24 h-12 opacity-30 hover:bg-gray-700" disabled>
                    Box
                </button>
                <button className="btn w-24 h-12 opacity-30 hover:bg-gray-700" disabled>
                    Active Directory
                </button>
                <button className="btn w-24 h-12 opacity-30 hover:bg-gray-700" disabled>
                    Auth0
                </button>
                <button className="btn w-24 h-12 opacity-30 hover:bg-gray-700" disabled>
                    Dropbox
                </button>
                <button className="btn w-24 h-12 opacity-30 hover:bg-gray-700" disabled>
                    IPFIX
                </button>
            </>
        );
    }

    return content;
};

interface NewDynamicProviderProps {
    provider: DynamicProvider;
    onClose: () => void;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

interface ProviderInput {
    tenantId?: string | null;
    name?: string;
    type?: string;
    AuthField_1?: string;
    AuthField_2?: string;
    AuthField_3?: string;
    AuthField_4?: string;
}

export const NewDynamicProvider = ({ provider, onClose, setIsModalOpen }: NewDynamicProviderProps): JSX.Element => {
    const [formData, setFormData] = useState<ProviderInput>({} as ProviderInput);

    const handleForm = (e: React.FormEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.currentTarget.name]: e.currentTarget.value,
        });
    };
    const submitForm = async (e: React.FormEvent, formData: ProviderInput) => {
        e.preventDefault();
        try {
            // Due to a limitation of graphql-codegen,
            // we need to map the AuthField_* to authField* input variables
            // This is a workaround for now, until we can get a fix for graphql-codegen
            const f = formData;
            const input = {
                tenantId,
                name: f.name,
                type: provider.Name,
                authField1: f.AuthField_1,
                authField2: f.AuthField_2,
                authField3: f.AuthField_3,
                authField4: f.AuthField_4,
            };
            console.log('Creating provider with input: ', input);
            await createProvider({ variables: { input: input } });
            setIsModalOpen(false);
        } catch (e) {
            console.log(e);
        }
    };

    const { tenantId } = useParams<{ tenantId: string }>();

    const [createProvider, { loading, data, error }] = useMutation(CREATE_PROVIDER, {
        refetchQueries: [{ query: LIST_PROVIDERS, variables: { tenantId } }],
    });

    if (provider) {
        const sortedFields = provider.Fields.slice().sort((a, b) => a.InputName.localeCompare(b.InputName));
        if (error) {
            return (
                <h4 className="text-red-500 font-medium mb-4">
                    There was an issue creating your new provider. <br />
                    <br />
                    Please retry. If this persists, please contact support.
                </h4>
            );
        }
        return (
            <>
                {!data && (
                    <form onSubmit={(e) => submitForm(e, formData)}>
                        <div className="space-y-2 text-gray-400">
                            <p className="text-sm">
                                Setting up a new integration with <span className="capitalize">{provider.Name}.</span>
                            </p>
                            <div className="space-y-4 py-2">
                                <input
                                    type="text"
                                    placeholder={`Provider Name, e.g. ${provider.DisplayName}`}
                                    name="name"
                                    className="input-gray text-white text-xs w-full"
                                    onChange={handleForm}
                                    required={true}
                                />
                                {sortedFields.map((field) => {
                                    if (!field.Generated) {
                                        return (
                                            <input
                                                onChange={handleForm}
                                                placeholder={field.DisplayName || field.Name}
                                                key={field.InputName}
                                                name={field.InputName}
                                                required={field.Mandatory}
                                                type="text"
                                                className="input-gray text-white text-xs w-full"
                                            />
                                        );
                                    }
                                })}
                            </div>
                            <div className="space-x-2 flex justify-end w-full">
                                <button
                                    type="button"
                                    className="btn text-xs"
                                    onClick={() => {
                                        setIsModalOpen(false);
                                        onClose;
                                    }}
                                    disabled={loading}
                                >
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary text-xs" disabled={loading}>
                                    Create
                                </button>
                            </div>
                        </div>
                    </form>
                )}
            </>
        );
    } else {
        return <>Please select a provider.</>;
    }
};

export type ProviderAuthOutput = {
    provider: Provider;
    authHeader: string;
    url: string;
};

interface ProviderAuthResponseProps {
    authData: ProviderAuthOutput;
}

export const ProviderAuthResponse = ({ authData }: ProviderAuthResponseProps): JSX.Element => {
    const urlClipboard = useClipboard({
        copiedTimeout: 2000,
    });
    const authClipboard = useClipboard({
        copiedTimeout: 2000,
    });

    return (
        <div className="space-y-4">
            <p>
                <span className="text-yellow-500 font-semibold">Pending!</span> Your new integration was created.
            </p>
            <p>
                To complete setting up this provider, it requires a WebHook to be configured in{' '}
                <span className="capitalize">{authData.provider.type}</span>.
            </p>
            <p>To set the WebHook up, you will need these authentication credentials.</p>
            <p>Please note them down as they will be shown only once.</p>
            <div className="w-2/3 space-y-4 ml-4">
                <div>
                    <label htmlFor="email" className="block font-semibold">
                        Web Hook URL
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm h-8">
                        <div className="relative flex items-stretch flex-grow focus-within:z-10 ">
                            <input
                                type="text"
                                name="url"
                                id="url"
                                value={authData.url}
                                readOnly={true}
                                placeholder="https://okta.example.com/api/v1/webhooks/incoming/1234567890"
                                className="input-gray rounded-none text-xs select-all"
                            />
                        </div>
                        <button
                            onClick={() => urlClipboard.copy(authData.url)}
                            className="-ml-px btn inline-flex bg-gray-800 border border-gray-500 font-medium rounded-r focus:z-10"
                        >
                            <span>{urlClipboard.copied ? 'Copied!' : 'Copy'}</span>
                        </button>
                    </div>
                </div>
                <div>
                    <label htmlFor="email" className="block font-semibold">
                        Web Hook Authorization Code
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm h-8">
                        <div className="relative flex items-stretch flex-grow focus-within:z-10">
                            <input
                                type="text"
                                name="authorization"
                                id="authorization"
                                value={authData.authHeader}
                                readOnly={true}
                                placeholder="Y2xpZW50X2lkOmNsaWVudF9zZWNyZXQ="
                                className="input-gray rounded-none text-xs select-all"
                            />
                        </div>
                        <button
                            onClick={() => authClipboard.copy(authData.authHeader)}
                            className="-ml-px btn inline-flex bg-gray-800 border border-gray-500 font-medium rounded-r focus:z-10"
                        >
                            <span>{authClipboard.copied ? 'Copied!' : 'Copy'}</span>
                        </button>
                    </div>
                </div>
                <a
                    href={`data:text/json;charset=utf-8,${encodeURIComponent(
                        JSON.stringify(
                            authData,
                            (key, value) => {
                                if (key === '__typename') {
                                    return undefined;
                                }
                                return value;
                            },
                            '\t',
                        ),
                    )}`}
                    download="auth-credentials.json"
                    className="btn w-44"
                >
                    Download Credentials
                </a>
            </div>
        </div>
    );
};
