import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import amplitude from 'amplitude-js';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import LogRocket from 'logrocket';
import { useEffect } from 'react';

const Auth0ProviderWithHistory = ({ children }: { children: JSX.Element }): JSX.Element => {
    const domain = window.__env__.AUTH0_DOMAIN || '';
    const clientId = window.__env__.AUTH0_CLIENT_ID || '';
    const audience = window.__env__.AUTH0_AUDIENCE || '';
    const organization = localStorage.getItem('org_id') || undefined;

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            redirectUri={window.location.origin}
            audience={audience}
            organization={organization}
        >
            <Auth0>{children}</Auth0>
        </Auth0Provider>
    );
};

export default Auth0ProviderWithHistory;

const Auth0 = ({ children }: { children: JSX.Element }) => {
    const { isLoading, error, user } = useAuth0();
    const ldClient = useLDClient();

    // Deal with Organization ID in localStorage
    useEffect(() => {
        if (user && user.org_id) {
            localStorage.setItem('org_id', String(user.org_id));
        }

        if (error) {
            localStorage.removeItem('org_id');
        }
    }, [error, user]);

    // Deal with identifying the user in Launch Darkly
    useEffect(() => {
        if (user && user.sub) {
            if (ldClient) {
                console.log(`Identifying user ${user.sub} to Launch Darkly`);
                ldClient.identify({ key: user.sub, name: user.name, email: user.email });
            }
        }
    }, [ldClient, user]);

    return (
        <>
            {isLoading && !error && <Loading />}
            {!isLoading && !error && children}
        </>
    );
};

const Loading = () => (
    <div className="flex h-screen">
        <div className="m-auto">
            <p className="text-gray-500 text-xs">Authenticating ...</p>
        </div>
    </div>
);
