import { TrashIcon } from '@heroicons/react/solid';

export type Auth0Connection = {
    id: string;
    name: string;
};

const auth0Connection = [
    {
        id: '1',
        name: 'dummy-con-1',
    },
    {
        id: '2',
        name: 'dummy-con-2',
    },
    {
        id: '3',
        name: 'dummy-con-3',
    },
];

export const Auth0Connections = (): JSX.Element => {
    return (
        <table className="table-auto w-full">
            <thead>
                <tr className="text-sm">
                    <th className="p-2" align="left">
                        Connection
                    </th>
                    <th className="p-2" align="right">
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody>
                {/* Customer List Item */}
                {auth0Connection &&
                    auth0Connection.map((connection: Auth0Connection) => (
                        <tr key={connection.id} className="border-gray-800 border-4 bg-gray-900">
                            <td className="p-2 pl-4" align="left">
                                {connection.name}
                            </td>
                            <td className="p-2" align="right">
                                <button className="btn inline-flex rounded-md hover:bg-red-600">
                                    <TrashIcon className="mr-2 w-4" />
                                    <span className="text-sm">Delete</span>
                                </button>
                            </td>
                        </tr>
                    ))}
            </tbody>
        </table>
    );
};
