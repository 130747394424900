export const capitalize = ([firstLetter, ...restOfWord]: string[]): string =>
    firstLetter.toUpperCase() + restOfWord.join('');

// take a string in bytes and return a string in the most appropriate unit
export const formatBytes = (bytes: number): string => {
    if (bytes < 1024) {
        return `${bytes} bytes`;
    }
    if (bytes < 1024 * 1024) {
        return `${(bytes / 1024).toFixed(2)} KB`;
    }
    if (bytes < 1024 * 1024 * 1024) {
        return `${(bytes / 1024 / 1024).toFixed(2)} MB`;
    }
    if (bytes < 1024 * 1024 * 1024 * 1024) {
        return `${(bytes / 1024 / 1024 / 1024).toFixed(2)} GB`;
    }
    if (bytes < 1024 * 1024 * 1024 * 1024 * 1024) {
        return `${(bytes / 1024 / 1024 / 1024 / 1024).toFixed(2)} TB`;
    }
    return `${(bytes / 1024 / 1024 / 1024 / 1024 / 1024).toFixed(2)} PB`;
};

export const classNames = (...classes: string[]): string => {
    return classes.filter(Boolean).join(' ');
};

export const transformName = (uglyName: string) => {
    const parts = uglyName.split(':');
    return parts[1];
};
