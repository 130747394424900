import { XIcon, CheckIcon } from '@heroicons/react/solid';

export type FeatureUser = {
    userId: string;
    name: string;
    email: string;
    // NEW
    role: string;
    enabled: boolean;
};

const featureUser = [
    {
        userId: '1',
        name: 'Bill Lumbergh',
        email: 'bill@initechcom',
        role: 'owner',
        enabled: true,
    },
    {
        userId: '2',
        name: 'Peter Gibbons',
        email: 'peter@initechcom',
        role: 'poweruser',
        enabled: true,
    },
    {
        userId: '3',
        name: 'Michael Bolton',
        email: 'michael@initechcom',
        role: 'poweruser',
        enabled: true,
    },
    {
        userId: '5',
        name: 'Milton Waddams',
        email: 'milton@initechcom',
        role: 'user',
        enabled: false,
    },
];

export const FeatureUsers = (): JSX.Element => {
    return (
        <table className="table-auto w-full">
            <thead>
                <tr className="text-sm">
                    <th className="p-2" align="left">
                        Name
                    </th>
                    <th className="p-2" align="left">
                        Email
                    </th>
                    <th className="p-2">Role</th>
                    <th className="p-2" align="right">
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody>
                {featureUser &&
                    featureUser.map((user: FeatureUser) => (
                        <tr key={user.userId} className="border-gray-800 border-4 bg-gray-900 h-14">
                            <td className="p-2 pl-4" align="left">
                                {user.name}
                            </td>
                            <td className="p-2" align="left">
                                {user.email}
                            </td>
                            <td className="p-2" align="center">
                                {user.role}
                            </td>
                            <td className="p-2" align="right">
                                {user.enabled ? (
                                    <div className="flex justify-end">
                                        <button className="btn rounded-r-none w-28 hover:bg-gray-600">
                                            <XIcon className="w-4 mr-2" />
                                            <span className="text-sm">Disable</span>
                                        </button>
                                        <span className="btn rounded-l-none w-28 bg-green-600 hover:bg-green-600">
                                            <CheckIcon className="w-4 mr-2" />
                                            <span className="text-sm">Enabled</span>
                                        </span>
                                    </div>
                                ) : (
                                    <div className="flex justify-end">
                                        <span className="btn rounded-r-none w-28 bg-red-600 hover:bg-red-600">
                                            <XIcon className="w-4 mr-2" />
                                            <span className="text-sm">Disabled</span>
                                        </span>
                                        <button className="btn rounded-l-none w-28  hover:bg-gray-600">
                                            <CheckIcon className="w-4 mr-2" />
                                            <span className="text-sm">Enable</span>
                                        </button>
                                    </div>
                                )}
                            </td>
                        </tr>
                    ))}
            </tbody>
        </table>
    );
};
