import React, { Dispatch, SetStateAction, useState } from 'react';
import { useMutation } from '@apollo/client';
import { DELETE_TENANT, LIST_TENANTS } from '../../../Graph/queries';
import { useHistory } from 'react-router-dom';

interface FormProps {
    isDeleteModalOpen: Dispatch<SetStateAction<boolean>>;
    tenantId: string;
}

export const DeleteTenant = ({ isDeleteModalOpen, tenantId }: FormProps): JSX.Element => {
    const [disableButton, setDisableButton] = useState(false);

    const [deleteTenant] = useMutation(DELETE_TENANT, {
        refetchQueries: [{ query: LIST_TENANTS }],
    });

    const [errorDeletingTenant, setErrorDeletingTenant] = useState('');

    const history = useHistory();

    const deleteThisTenant = async (tenantId: string) => {
        console.log('deleting tenant: ' + tenantId);
        setDisableButton(true);
        try {
            await deleteTenant({ variables: { tenantId } });
        } catch (e) {
            setErrorDeletingTenant('Error deleting Tenant');
        }
        history.push('/tenants');
    };

    return (
        <>
            <div
                className="fixed z-10 inset-0 overflow-hidden"
                aria-labelledby="modal-title"
                role="dialog"
                aria-modal="true"
            >
                <div className="items-end justify-center min-h-screen p-5 text-center block">
                    <div
                        className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity flex items-center justify-center"
                        aria-hidden="true"
                    >
                        <div
                            className="inline-block bg-gray-800 rounded-md text-left overflow-hidden shadow-xl transform 
                               transition-all align-middle w-4/12 max-w-7xl"
                        >
                            <div className="text-white py-5 px-3 border-gray-600" style={{ borderBottomWidth: '1px' }}>
                                Delete this Tenant
                            </div>
                            <div className="p-5">
                                {errorDeletingTenant && (
                                    <span className="text-sm text-red-600 mr-2 mb-5">{errorDeletingTenant}</span>
                                )}
                                <p>Are you sure you want to delete this tenant?</p>
                            </div>
                            <div className="p-5 flex justify-end">
                                <button
                                    type="button"
                                    className="btn inline-flex rounded-md ml-2"
                                    onClick={() => isDeleteModalOpen(false)}
                                >
                                    <span className="text-sm">Cancel</span>
                                </button>
                                <button
                                    className="btn btn-primary inline-flex rounded-md ml-2"
                                    style={disableButton ? { opacity: '0.5', pointerEvents: 'none' } : {}}
                                    type="button"
                                    onClick={() => deleteThisTenant(tenantId)}
                                    disabled={disableButton}
                                >
                                    <span className="text-sm">{disableButton ? 'Deleting...' : 'Confirm Delete'}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
