import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Auth0Organizations } from './Auth0Organizations';
import { Auth0Connections } from './Auth0Connections';

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

function Orphans(): JSX.Element {
    const [tab, setTab] = useState('organizations');

    return (
        <>
            <Helmet>
                <title>Auth0 Entities - 00 Ops</title>
                <link rel="OpsAuth0" href="https://ops.doublezerosecurity.com/auth0" />
            </Helmet>
            <div className="h-full">
                <section className="h-full relative text-gray-400 bg-gray-800 body-font">
                    <div className="container mx-auto py-5 px-3">
                        <section className="py-5 flex justify-between items-center">
                            <h1 className="text-3xl text-gray-100 font-semibold">Auth0 Entities</h1>
                        </section>
                        <section
                            className="my-5 flex justify-between items-center border-b-2"
                            style={{ borderBottom: '1px solid rgba(255,255,255,0.25)' }}
                        >
                            {/* Tab Group */}
                            <div id="tabs" className="flex">
                                {/* Tab Active */}
                                <button
                                    type="button"
                                    className={classNames('tab', tab == 'organizations' ? 'tab-active' : '')}
                                    onClick={() => setTab('organizations')}
                                >
                                    <span className="mr-2 text-base">Organizations</span>
                                </button>
                                {/* Tab Inactive */}
                                <button
                                    type="button"
                                    className={classNames('tab', tab == 'connections' ? 'tab-active' : '')}
                                    onClick={() => setTab('connections')}
                                >
                                    <span className="mr-2 text-base">Connections</span>
                                </button>
                            </div>
                        </section>
                        <section className="flex justify-between items-center">
                            {
                                {
                                    organizations: <Auth0Organizations />,
                                    connections: <Auth0Connections />,
                                }[tab]
                            }
                        </section>
                    </div>
                </section>
            </div>
        </>
    );
}
export default Orphans;
