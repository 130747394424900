import { XIcon, CheckIcon } from '@heroicons/react/solid';

export type FeatureTenant = {
    tenantId: string;
    name: string;
    displayName: string;
    // NEW
    userCount: number;
    enabled: boolean;
};

const featureTenant = [
    {
        tenantId: '1',
        name: 'dm_scranton',
        displayName: 'Dunder-Mifflin Scranton',
        userCount: 16,
        enabled: true,
    },
    {
        tenantId: '2',
        name: 'dm_hq',
        displayName: 'Dunder-Mifflin HQ',
        userCount: 8,
        enabled: true,
    },
    {
        tenantId: '3',
        name: 'initech',
        displayName: 'Initech',
        userCount: 9,
        enabled: false,
    },
    {
        tenantId: '4',
        name: 'e_corp',
        displayName: 'E-Corp',
        userCount: 16,
        enabled: true,
    },
    {
        tenantId: '5',
        name: 'pat_ann',
        displayName: 'Pat Anndustries',
        userCount: 16,
        enabled: true,
    },
];

export const FeatureTenants = (): JSX.Element => {
    return (
        <table className="table-auto w-full">
            <thead>
                <tr className="text-sm">
                    <th className="p-2" align="left">
                        Tenant
                    </th>
                    <th className="p-2" align="left">
                        Display Name
                    </th>
                    <th className="p-2">Users</th>
                    <th className="p-2" align="right">
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody>
                {featureTenant &&
                    featureTenant.map((featureTenant: FeatureTenant) => (
                        <tr key={featureTenant.tenantId} className="border-gray-800 border-4 bg-gray-900 h-14">
                            <td className="p-2 pl-4" align="left">
                                {featureTenant.name}
                            </td>
                            <td className="p-2" align="left">
                                {featureTenant.displayName}
                            </td>
                            <td className="p-2" align="center">
                                {featureTenant.userCount} Users
                            </td>
                            <td className="p-2" align="right">
                                {featureTenant.enabled ? (
                                    <div className="flex justify-end">
                                        <button className="btn rounded-r-none w-28 hover:bg-gray-600">
                                            <XIcon className="w-4 mr-2" />
                                            <span className="text-sm">Disable</span>
                                        </button>
                                        <span className="btn rounded-l-none w-28 bg-green-600 hover:bg-green-600">
                                            <CheckIcon className="w-4 mr-2" />
                                            <span className="text-sm">Enabled</span>
                                        </span>
                                    </div>
                                ) : (
                                    <div className="flex justify-end">
                                        <span className="btn rounded-r-none w-28 bg-red-600 hover:bg-red-600">
                                            <XIcon className="w-4 mr-2" />
                                            <span className="text-sm">Disabled</span>
                                        </span>
                                        <button className="btn rounded-l-none w-28  hover:bg-gray-600">
                                            <CheckIcon className="w-4 mr-2" />
                                            <span className="text-sm">Enabled</span>
                                        </button>
                                    </div>
                                )}
                            </td>
                        </tr>
                    ))}
            </tbody>
        </table>
    );
};
