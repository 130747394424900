import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FeatureUsers } from './FeatureUsers';
import { FeatureTenants } from './FeatureTenants';

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

function Features(): JSX.Element {
    const [tab, setTab] = useState('tenants');

    return (
        <>
            <Helmet>
                <title>Features - 00 Ops</title>
                <link rel="OpsFeatures" href="https://ops.doublezerosecurity.com/features" />
            </Helmet>
            <div className="h-full">
                <section className="h-full relative text-gray-400 bg-gray-800 body-font">
                    <div className="container mx-auto py-5 px-3">
                        <section className="py-5 flex justify-between items-center">
                            <h1 className="text-3xl text-gray-100 font-semibold">Features</h1>
                            <div className="inline-flex items-center"></div>
                        </section>
                        <section
                            className="my-5 flex justify-between items-center"
                            style={{ borderBottom: '1px solid rgba(255,255,255,0.25)' }}
                        >
                            {/* Tab Group */}
                            <div id="tabs" className="flex">
                                <button
                                    type="button"
                                    className={classNames('tab', tab == 'tenants' ? 'tab-active' : '')}
                                    onClick={() => setTab('tenants')}
                                >
                                    <span className="badge mr-2">10</span>
                                    <span className="text-base">Tenants</span>
                                </button>
                                <button
                                    type="button"
                                    className={classNames('tab', tab == 'users' ? 'tab-active' : '')}
                                    onClick={() => setTab('users')}
                                >
                                    <span className="badge mr-2">2</span>
                                    <span className="text-base">Users</span>
                                </button>
                            </div>
                            <div className="inline-flex"></div>
                        </section>
                        <section className="flex justify-between items-center">
                            {
                                {
                                    tenants: <FeatureTenants />,
                                    users: <FeatureUsers />,
                                }[tab]
                            }
                        </section>
                    </div>
                </section>
                <section>{/* <ConfigurationModal /> */}</section>
            </div>
        </>
    );
}
export default Features;
