import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { LIST_TENANTS } from '../../Graph/queries';
import { CreateTenant } from './Components/CreateTenant';
import { useHistory } from 'react-router-dom';
import { formatBytes } from 'Utilities/utils';
import { NavLink } from 'react-router-dom';
import { ChevronRightIcon, PlusIcon, HomeIcon } from '@heroicons/react/solid';
import { classNames } from 'Utilities/utils';

export type Tenant = {
    tenantId: string;
    name: string;
    slug: string;
    storageUsed: number;
    firstEventAt: number;
    lastEventAt: number;
};

function TenantList(): JSX.Element {
    const history = useHistory();
    const { loading, error, data } = useQuery(LIST_TENANTS);

    const [tab, setTab] = useState('all');
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

    let content = <></>;

    if (loading) {
        content = (
            <>
                <tr>
                    <td colSpan={7} className="px-6 py-4 whitespace-nowrap text-sm font-medium text-center">
                        <div className="text-sm text-gray-500 justify-center flex items-center">
                            <div className="loader mr-2" />
                            Loading Tenant...
                        </div>
                    </td>
                </tr>
            </>
        );
    } else if (error) {
        content = (
            <>
                <tr>
                    <td
                        colSpan={7}
                        className="px-6 py-4 whitespace-nowrap text-sm font-medium text-center text-red-500"
                    >
                        Could not load Tenants. Please retry later.
                    </td>
                </tr>
            </>
        );
    } else if (data) {
        content = data.listTenants.map((tenant: Tenant) => (
            <tr
                key={tenant.tenantId}
                onClick={() => history.push(`/tenants/${tenant.tenantId}`)}
                className="cursor-pointer hover:bg-blue-400/5 transition-all duration-75"
            >
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white" align="left">
                    {tenant.name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-400" align="left">
                    {tenant.slug}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-400" align="left">
                    {tenant.tenantId}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-400" align="left">
                    {formatBytes(tenant.storageUsed)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-400" align="left">
                    {tenant.firstEventAt != 0 ? new Date(tenant.firstEventAt / 1000000).toLocaleString() : 'No Date'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-400" align="left">
                    {tenant.lastEventAt != 0 ? new Date(tenant.lastEventAt / 1000000).toLocaleString() : 'No Date'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap" align="right">
                    <div
                        className="flex font-medium text-sm items-center justify-end text-blue-500 hover:text-blue-300 cursor-pointer"
                        onClick={() => history.push(`/tenants/${tenant.tenantId}`)}
                    >
                        View Tenant
                        <ChevronRightIcon className="h-5 w-5 ml-2" />
                    </div>
                </td>
            </tr>
        ));
    }

    return (
        <>
            <Helmet>
                <title>Tenants - 00 Ops</title>
                <link rel="OpsTenants" href="https://ops.doublezerosecurity.com/tenants" />
            </Helmet>
            <div>
                <section className="h-full relative text-gray-400 bg-gray-900 body-font">
                    <div className="container mx-auto py-5 px-3">
                        <section className="lg:flex lg:items-center lg:justify-between mb-3">
                            <div className="flex-1 min-w-0">
                                <nav className="flex" aria-label="Breadcrumb">
                                    <ol role="list" className="flex items-center space-x-4">
                                        <li>
                                            <div className="flex">
                                                <NavLink
                                                    to="/"
                                                    className="text-sm font-medium text-gray-300 hover:text-white"
                                                >
                                                    <HomeIcon className="h-5 w-5" />
                                                </NavLink>
                                            </div>
                                        </li>
                                    </ol>
                                </nav>
                                <h2 className="my-5 text-2xl font-bold leading-7 text-white sm:text-3xl sm:truncate">
                                    Tenants
                                </h2>
                            </div>
                            <div className="flex lg:mt-0 lg:ml-4">
                                <span className="sm:ml-3">
                                    <button
                                        className="btn-primary inline-flex py-2 px-4 text-white rounded-md text-sm"
                                        onClick={() => setIsCreateModalOpen(true)}
                                    >
                                        <PlusIcon className="w-5 mr-2" /> Onboard New Tenant
                                    </button>
                                </span>
                            </div>
                        </section>

                        <section
                            className="my-5 flex justify-between items-center"
                            style={{ borderBottom: '1px solid rgba(255,255,255,0.25)' }}
                        >
                            <div id="tabs" className="flex">
                                <button
                                    type="button"
                                    className={classNames('tab mb-[-1px]', tab == 'all' ? 'tab-active' : '')}
                                    onClick={() => setTab('all')}
                                >
                                    {data && <span className="badge mr-2">{data.listTenants.length}</span>}
                                    {loading && <span className="loader h-4 w-4 ml-1 mr-3" />}
                                    <span className="text-base">All Tenants</span>
                                </button>
                            </div>
                        </section>
                        <section className="flex flex-col">
                            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                    <div className="shadow overflow-hidden border-b border-gray-700 sm:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-700">
                                            <thead className="bg-gray-700">
                                                <tr className="text-sm">
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                                                        align="left"
                                                    >
                                                        Tenant Name
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                                                        align="left"
                                                    >
                                                        Slug
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                                                        align="left"
                                                    >
                                                        UUID
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                                                        align="left"
                                                    >
                                                        Storage Used
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                                                        align="left"
                                                    >
                                                        First Event
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                                                        align="left"
                                                    >
                                                        Last Event
                                                    </th>
                                                    <th scope="col" className="relative px-6 py-3" align="right"></th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-gray-800 divide-y divide-gray-700">{content}</tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
                <section>{isCreateModalOpen && <CreateTenant isCreateModalOpen={setIsCreateModalOpen} />}</section>
            </div>
        </>
    );
}
export default TenantList;
