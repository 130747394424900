import { useAuth0 } from '@auth0/auth0-react';
import jwtDecode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { Environments, JwtPayload } from 'Types/types';

export const useTenant = (): string | null => {
    const { getAccessTokenSilently } = useAuth0();
    const [tenantId, setTenantId] = useState<string | null>(localStorage.getItem('tenantId'));

    useEffect(() => {
        (async () => {
            if (!tenantId) {
                let token;
                try {
                    token = await getAccessTokenSilently();
                    // There should be a tenantId in the JWT token
                    // the tenantId is used when making various API calls.
                    //
                    // If the tenantId is not set, this usually means the user is not
                    // bound to the tenant correctly in auth0
                    const decoded_token = jwtDecode<JwtPayload>(token);
                    const tenantId = decoded_token['https://doublezero.io/tenant'];
                    if (tenantId) {
                        localStorage.setItem('tenantId', tenantId);
                        setTenantId(tenantId);
                    }
                } catch (error) {
                    console.log('Could not get the tenantId:', error);
                }
            }
        })();
    }, [getAccessTokenSilently, tenantId]);

    return tenantId;
};

export const useEnvironment = (): Environments => {
    return getEnvironment();
};

export const getEnvironment = (): Environments => {
    if (
        process.env.NODE_ENV === 'development' ||
        window.__env__.ENVIRONMENT === 'dev' ||
        window.__env__.ENVIRONMENT === 'local'
    ) {
        return 'dev';
    } else if (window.__env__.ENVIRONMENT === 'staging') {
        return 'staging';
    } else if (window.__env__.ENVIRONMENT === 'prod') {
        return 'prod';
    }
    return 'dev';
};
