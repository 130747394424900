import React, { Dispatch, SetStateAction, useState } from 'react';
import { useMutation } from '@apollo/client';
import { ONBOARD_TENANT, LIST_TENANTS } from '../../../Graph/queries';

interface FormProps {
    isCreateModalOpen: Dispatch<SetStateAction<boolean>>;
}

interface FormData {
    firstName: string;
    lastName: string;
    email: string;
    slug: string;
    name: string;
    cloud: string;
    region: string;
    password?: string;
    tos?: string | boolean;
}

export const CreateTenant = ({ isCreateModalOpen }: FormProps): JSX.Element => {
    // const closeConfig = () => dispatch({ type: 'toggle-config' });
    // useHotkeys('esc', closeConfig);
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);
    const [formData, setFormData] = useState<FormData>({} as FormData);
    const [onboardTenant] = useMutation(ONBOARD_TENANT, {
        refetchQueries: [{ query: LIST_TENANTS }],
    });

    const submitForm = async (e: React.FormEvent, formData: FormData) => {
        e.preventDefault();
        console.log(formData);

        // remove fields not accepted by the backend: update this if the backend changes
        // convert the checkbox value to a boolean
        formData.tos = true;
        setDisableSubmitButton(true);

        try {
            const result = await onboardTenant({ variables: { input: formData } });
            if (result.data.onboardTenant.result == true) {
                isCreateModalOpen(false);
            } else {
                setDisableSubmitButton(false);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleForm = (e: React.FormEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.currentTarget.id]: e.currentTarget.value,
        });
    };

    return (
        <>
            <div
                className="fixed z-10 inset-0 overflow-hidden"
                aria-labelledby="modal-title"
                role="dialog"
                aria-modal="true"
            >
                <div className="items-end justify-center min-h-screen p-5 text-center block">
                    <div
                        className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity flex items-center justify-center"
                        aria-hidden="true"
                    >
                        <div
                            className="inline-block bg-gray-800 rounded-md text-left overflow-hidden shadow-xl transform 
                               transition-all align-middle w1/4"
                        >
                            <form onSubmit={(e) => submitForm(e, formData)}>
                                <div
                                    className="text-white py-5 px-3 border-gray-600"
                                    style={{ borderBottomWidth: '1px' }}
                                >
                                    Onboard New Tenant
                                </div>
                                <div className="px-5 py-3 mb-3 overflow-auto">
                                    <div className="mt-3">
                                        <label htmlFor="firstName" className="block text-sm font-medium text-gray-300">
                                            Owner First Name
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                id="firstName"
                                                name="firstName"
                                                type="text"
                                                autoComplete="firstName"
                                                required
                                                className="input-gray"
                                                onChange={handleForm}
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <label htmlFor="lastName" className="block text-sm font-medium text-gray-300">
                                            Owner Last Name
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                id="lastName"
                                                name="lastName"
                                                type="text"
                                                autoComplete="lastName"
                                                required
                                                className="input-gray"
                                                onChange={handleForm}
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <label htmlFor="email" className="block text-sm font-medium text-gray-300">
                                            Owner Email
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                autoComplete="email"
                                                required
                                                className="input-gray"
                                                onChange={handleForm}
                                            />
                                        </div>
                                    </div>

                                    <div className="mt-3">
                                        <label htmlFor="name" className="block text-sm font-medium text-gray-300">
                                            Tenant Short Name
                                        </label>
                                        <p className="text-gray-600 text-xs">
                                            {`Must only contain lowercase characters, '-', and`}
                                            <br />
                                            {`start and end with a letter or number`}
                                        </p>
                                        <div className="mt-1">
                                            <input
                                                id="slug"
                                                name="slug"
                                                type="text"
                                                required
                                                pattern="^[\da-z][\da-z-]{1,48}[\da-z]$"
                                                autoComplete="new-password"
                                                className="input-gray"
                                                onChange={handleForm}
                                            />
                                        </div>
                                    </div>

                                    <div className="mt-3">
                                        <label
                                            htmlFor="displayName"
                                            className="block text-sm font-medium text-gray-300"
                                        >
                                            Tenant Display Name
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                id="name"
                                                name="name"
                                                type="text"
                                                required
                                                autoComplete="new-password"
                                                className="input-gray"
                                                onChange={handleForm}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="p-5 flex justify-end">
                                    <button
                                        type="button"
                                        className="btn inline-flex rounded-md ml-2"
                                        onClick={() => isCreateModalOpen(false)}
                                    >
                                        <span className="text-sm">Cancel</span>
                                    </button>
                                    <button
                                        className="btn btn-primary inline-flex rounded-md ml-2"
                                        style={disableSubmitButton ? { opacity: '0.5', pointerEvents: 'none' } : {}}
                                        type="submit"
                                        disabled={disableSubmitButton}
                                    >
                                        <span className="text-sm">
                                            {disableSubmitButton ? 'Creating' : 'Create Tenant'}
                                        </span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <span className="inline-block align-middle h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                </div>
            </div>
        </>
    );
};
