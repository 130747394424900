import { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery } from '@apollo/client';
import { GET_TENANT } from 'Graph/queries';
import { useParams } from 'react-router-dom';
import {
    ArrowRightIcon,
    ClipboardListIcon,
    DatabaseIcon,
    HomeIcon,
    OfficeBuildingIcon,
    ShareIcon,
    TrashIcon,
    UserGroupIcon,
} from '@heroicons/react/solid';
import { Users } from './Components/Users';
import { Roles } from './Components/Roles';
import { NavLink } from 'react-router-dom';
import { DeleteTenant } from './Components/DeleteTenant';
import { Tab } from '@headlessui/react';
import { CalendarIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { classNames, formatBytes } from 'Utilities/utils';
import { format } from 'date-fns';
import { Providers } from './Components/Providers';

export type Tenant = {
    tenantId: string;
    name: string;
    slug: string;
    storageUsed: number;
    firstEventAt: number;
    lastEventAt: number;
};

function TenantDetails(): JSX.Element {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const { tenantId } = useParams<{ tenantId: string }>();
    const { loading, error, data } = useQuery(GET_TENANT, { variables: { tenantId } });

    let content = <></>;

    if (loading) {
        content = (
            <div className="text-sm text-gray-500 justify-center flex items-center p-3">
                <div className="loader mr-2" />
                Loading Tenant...
            </div>
        );
    } else if (error) {
        content = <h4 className="text-sm text-red-600">Could not load tenant. Please retry later.</h4>;
    } else if (data) {
        const { tenantId, name, slug, storageUsed, firstEventAt, lastEventAt } = data.getTenant;
        content = (
            <>
                <Helmet>
                    <title>{name} - 00 Ops</title>
                    <link rel="OpsTenantInfo" href={`https://ops.doublezerosecurity.com/tenants/${slug}`} />
                </Helmet>
                <section className="flex items-center justify-between mb-3">
                    <div className="flex-1 min-w-0">
                        <nav className="flex" aria-label="Breadcrumb">
                            <ol role="list" className="flex items-center space-x-4">
                                <li>
                                    <div className="flex">
                                        <NavLink to="/" className="text-sm font-medium text-gray-300 hover:text-white">
                                            <HomeIcon className="h-5 w-5" />
                                        </NavLink>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center">
                                        <ChevronRightIcon
                                            className="flex-shrink-0 h-5 w-5 text-gray-500"
                                            aria-hidden="true"
                                        />
                                        <NavLink
                                            to="/tenants"
                                            className="ml-4 text-sm font-medium text-gray-300 hover:text-white"
                                        >
                                            Tenants
                                        </NavLink>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                        <h2 className="my-5 text-2xl font-bold leading-7 text-white sm:text-3xl sm:truncate">{name}</h2>
                        <div className="flex flex-col space-y-1 sm:space-y-0 sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
                            <div className="flex items-center text-sm text-gray-300 tooltip" data-text="Slug">
                                <OfficeBuildingIcon
                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500"
                                    aria-hidden="true"
                                />
                                {slug}
                            </div>
                            <div className="flex items-center text-sm text-gray-300 tooltip" data-text="Storage Used">
                                <DatabaseIcon
                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500"
                                    aria-hidden="true"
                                />
                                {formatBytes(storageUsed)}
                            </div>
                            <div className="flex items-center space-x-2">
                                <div
                                    className="flex items-center text-sm text-gray-300 tooltip"
                                    data-text="First Event"
                                >
                                    <CalendarIcon
                                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500"
                                        aria-hidden="true"
                                    />
                                    {firstEventAt != 0
                                        ? format(new Date(firstEventAt / 1000000), 'MM-dd-yyyy')
                                        : 'No Date'}
                                </div>
                                <ArrowRightIcon className="h-5 w-5 text-gray-500" />
                                <div className="flex items-center text-sm text-gray-300 tooltip" data-text="Last Event">
                                    <CalendarIcon
                                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500"
                                        aria-hidden="true"
                                    />
                                    {lastEventAt != 0
                                        ? format(new Date(lastEventAt / 1000000), 'MM-dd-yyyy')
                                        : 'No Date'}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex lg:mt-0 lg:ml-4">
                        <span className="sm:ml-3">
                            <button
                                className="btn inline-flex rounded-md ml-2"
                                onClick={() => setIsDeleteModalOpen(true)}
                            >
                                <TrashIcon className="mr-2 w-4" />
                                <span className="text-sm">Delete Tenant</span>
                            </button>
                        </span>
                    </div>
                </section>
                <section className="mt-6">
                    <Tab.Group
                        onChange={(index) => {
                            console.log('Changed selected tab to:', index);
                        }}
                    >
                        <Tab.List className="flex border-b border-gray-600 mb-5">
                            <Tab as={Fragment}>
                                {({ selected }) => (
                                    <button
                                        type="button"
                                        className={classNames('tab mb-[-1px]', selected ? 'tab-active' : '')}
                                    >
                                        <UserGroupIcon className="h-4 w-4 mr-2" />
                                        Users
                                    </button>
                                )}
                            </Tab>
                            <Tab as={Fragment}>
                                {({ selected }) => (
                                    <button
                                        type="button"
                                        className={classNames('tab mb-[-1px]', selected ? 'tab-active' : '')}
                                    >
                                        <ClipboardListIcon className="h-4 w-4 mr-2" />
                                        Roles
                                    </button>
                                )}
                            </Tab>
                            <Tab as={Fragment}>
                                {({ selected }) => (
                                    <button
                                        type="button"
                                        className={classNames('tab mb-[-1px]', selected ? 'tab-active' : '')}
                                    >
                                        <ShareIcon className="h-4 w-4 mr-2" />
                                        Providers
                                    </button>
                                )}
                            </Tab>
                        </Tab.List>

                        <Tab.Panels>
                            <Tab.Panel>
                                <Users />
                            </Tab.Panel>
                            <Tab.Panel>
                                <Roles tenantId={tenantId} />
                            </Tab.Panel>
                            <Tab.Panel>
                                <Providers />
                            </Tab.Panel>
                        </Tab.Panels>
                    </Tab.Group>
                </section>
            </>
        );
    }

    return (
        <>
            <div className="h-full text-gray-400 bg-gray-900 ">
                <section className="body-font">
                    <div className="container mx-auto py-5 px-3">{content}</div>
                </section>
                <section>
                    {isDeleteModalOpen && <DeleteTenant isDeleteModalOpen={setIsDeleteModalOpen} tenantId={tenantId} />}
                </section>
            </div>
        </>
    );
}
export default TenantDetails;
