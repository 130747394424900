import { withAuthenticationRequired } from '@auth0/auth0-react';

import { FunctionComponent } from 'react';
import { Route } from 'react-router-dom';

type ProtectedRouteProps = {
    component: FunctionComponent;
    [key: string]: unknown;
};

const ProtectedRoute = (props: ProtectedRouteProps): JSX.Element => {
    const { component, ...args } = props;

    return <Route component={withAuthenticationRequired(component)} {...args} />;
};

export default ProtectedRoute;
