import React, { Dispatch, Fragment, SetStateAction, useState } from 'react';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { CheckIcon, XIcon } from '@heroicons/react/solid';
import { CREATE_USER, LIST_USERS, LIST_ROLES } from 'Graph/queries';
import { Transition, Dialog } from '@headlessui/react';
import { transformName } from 'Utilities/utils';
import { useParams } from 'react-router-dom';

interface FormProps {
    setOpenModal: Dispatch<SetStateAction<boolean>>;
}

interface FormData {
    name: string;
    email: string;
    role: string;
}

export const CreateUser = ({ setOpenModal }: FormProps): JSX.Element => {
    const { tenantId } = useParams<{ tenantId: string }>();
    const client = useApolloClient();

    const { data: roleData } = useQuery(LIST_ROLES, { variables: { tenantId } });

    const [createUser] = useMutation(CREATE_USER);
    const [errorCreatingUser, setErrorCreatingUser] = useState('');

    const [formData, setFormData] = useState<FormData>({} as FormData);
    const handleForm = (e: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLSelectElement>) => {
        setFormData({
            ...formData,
            [e.currentTarget.id]: e.currentTarget.value,
        });
    };

    const submitForm = async (e: React.FormEvent, formData: FormData) => {
        e.preventDefault();
        const newUser = {
            name: formData.name,
            email: formData.email,
            tenantId: tenantId,
            roleIds: [formData.role],
        };
        try {
            const result = await createUser({
                variables: {
                    input: newUser,
                },
            });

            const users = client.readQuery({ query: LIST_USERS, variables: { tenantId } });
            const newUsers = [...users.listUsers, result.data.createUser];
            client.writeQuery({
                query: LIST_USERS,
                variables: { tenantId },
                data: { listUsers: newUsers },
            });

            setOpenModal(false);
        } catch (e) {
            console.log(e);
            setErrorCreatingUser('Error creating user');
        }
    };

    return (
        <>
            <Transition.Root show={true} as={Fragment}>
                <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => setOpenModal(false)}>
                    <div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">
                        <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <div className="relative inline-block align-middle text-left shadow-xl transform transition-all w-1/4">
                            <div className="relative rounded-lg bg-gray-800 p-5">
                                <h2 className="text-lg font-medium text-gray-300 mb-3">Create a new user</h2>
                                {errorCreatingUser && <div className="text-sm text-red-600">{errorCreatingUser}</div>}
                                <form onSubmit={(e) => submitForm(e, formData)} className="w-full space-y-4">
                                    <div>
                                        <label className="text-xs text-gray-400 mb-1">Full Name</label>
                                        <input
                                            id="name"
                                            name="name"
                                            type="text"
                                            placeholder="Name..."
                                            autoComplete="name"
                                            required
                                            className="input-gray text-xs w-full"
                                            minLength={3}
                                            onChange={handleForm}
                                        />
                                    </div>

                                    <div>
                                        <label className="text-xs text-gray-400 mb-1">Email Address</label>
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            placeholder="Email..."
                                            autoComplete="email"
                                            required
                                            className="input-gray text-xs w-full"
                                            onChange={handleForm}
                                        />
                                    </div>

                                    <div>
                                        <label className="text-xs text-gray-400 mb-1">User Role</label>
                                        <select
                                            id="role"
                                            name="role"
                                            autoComplete="role"
                                            required
                                            className="input-gray text-xs rounded-none"
                                            onChange={handleForm}
                                        >
                                            <option value="" selected disabled>
                                                Select a role...
                                            </option>
                                            {roleData &&
                                                roleData.listRoles.map((role: { name: string; roleId: string }) => {
                                                    const roleName = transformName(role.name);

                                                    return (
                                                        <option key={role.roleId} value={role.roleId}>
                                                            {roleName}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                    </div>

                                    <div className="flex justify-end space-x-2 pt-4">
                                        <button
                                            type="button"
                                            className="btn inline-flex text-xs items-center"
                                            onClick={() => setOpenModal(false)}
                                        >
                                            <XIcon className="w-4 mr-2" />
                                            <span>Cancel</span>
                                        </button>

                                        <button
                                            type="submit"
                                            className="btn btn-primary inline-flex text-xs items-center"
                                        >
                                            <CheckIcon className="w-4 mr-2" />
                                            <span>Save</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};
