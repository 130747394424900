import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { ExternalLinkIcon } from '@heroicons/react/solid';
import { CustomerTenantsModal } from './CustomerTenantsModal';

export type Customer = {
    customerId: string;
    name: string;
    openCases: number;
    closedCases: number;
    tenants: Array<Object>;
    hubSpotLink: string;
};

const customer = [
    {
        customerId: '1',
        name: 'Dunder-Mifflin',
        openCases: 3,
        closedCases: 10,
        hubSpotLink: 'https://doublezero.io',
        tenants: [
            {
                tenantId: '1',
                name: 'dm_scranton',
                cloud: 'aws',
                region: 'us',
                status: 1,
                userCount: 16,
                displayName: 'Dunder-Mifflin Scranton',
            },
            {
                tenantId: '2',
                name: 'dm_hq',
                cloud: 'aws',
                region: 'us',
                status: 1,
                userCount: 30,
                displayName: 'Dunder-Mifflin HQ',
            },
        ],
    },
    {
        customerId: '2',
        name: 'Initech',
        openCases: 3,
        closedCases: 10,
        hubSpotLink: 'https://doublezero.io',
        tenants: [
            {
                tenantId: '3',
                name: 'initech',
                cloud: 'aws',
                region: 'us',
                status: 2,
                userCount: 24,
                displayName: 'Initech',
            },
        ],
    },
    {
        customerId: '3',
        name: 'E-Corp',
        openCases: 0,
        closedCases: 10,
        hubSpotLink: 'https://doublezero.io',
        tenants: [
            {
                tenantId: '4',
                name: 'e_corp',
                cloud: 'aws',
                region: 'us',
                status: 3,
                userCount: 525,
                displayName: 'E-Corp',
            },
        ],
    },
    {
        customerId: '4',
        name: 'Pat Anndustries',
        openCases: 0,
        closedCases: 0,
        hubSpotLink: 'https://doublezero.io',
        tenants: [
            {
                tenantId: '5',
                name: 'pat_ann',
                cloud: 'aws',
                region: 'us',
                status: 4,
                userCount: 1,
                displayName: 'Pat Anndustries',
            },
        ],
    },
];

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

const openCases = customer.filter((customer: Customer) => customer.openCases > 0);

function Customers(): JSX.Element {
    const [tab, setTab] = useState('all');
    const [customerList, setCustomerList] = useState(customer);
    const [isTenantModalOpen, setIsTenantModalOpen] = useState(false);

    useEffect(() => {
        if (tab == 'all') {
            setCustomerList(customer);
        } else if (tab == 'open') {
            setCustomerList(openCases);
        }
    }, [tab]);

    return (
        <>
            <Helmet>
                <title>Customers - 00 Ops</title>
                <link rel="OpsCustomers" href="https://ops.doublezerosecurity.com/customers" />
            </Helmet>
            <div className="h-full">
                <section className="h-full relative text-gray-400 bg-gray-800 body-font">
                    <div className="container mx-auto py-5 px-3">
                        <section className="py-5 flex justify-between items-center">
                            <h1 className="text-3xl text-gray-100 font-semibold">Customers</h1>
                        </section>
                        <section
                            className="my-5 flex justify-between items-center"
                            style={{ borderBottom: '1px solid rgba(255,255,255,0.25)' }}
                        >
                            {/* Tab Group */}
                            <div id="tabs" className="flex">
                                <button
                                    type="button"
                                    className={classNames('tab', tab == 'all' ? 'tab-active' : '')}
                                    onClick={() => setTab('all')}
                                >
                                    <span className="badge mr-2">10</span>
                                    <span className="text-base">All Customers</span>
                                </button>
                                <button
                                    type="button"
                                    className={classNames('tab', tab == 'open' ? 'tab-active' : '')}
                                    onClick={() => setTab('open')}
                                >
                                    <span className="badge badge-danger mr-2">2</span>
                                    <span className="text-base">With Open Cases</span>
                                </button>
                            </div>
                        </section>
                        <section className="flex justify-between items-center">
                            {/* Customers Table */}
                            <table className="table-auto w-full">
                                <thead>
                                    <tr className="text-sm">
                                        <th className="p-2" align="left">
                                            Customer Name
                                        </th>
                                        <th className="p-2">Open Cases</th>
                                        <th className="p-2">Closed Cases</th>
                                        <th className="p-2">Tenants</th>
                                        <th className="p-2" align="right">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {customerList &&
                                        customerList.map((customer: Customer) => (
                                            <tr
                                                key={customer.customerId}
                                                className="border-gray-800 border-4 bg-gray-900 hover:bg-gray-700 cursor-pointer"
                                                onClick={() => setIsTenantModalOpen(true)}
                                            >
                                                <td className="p-2 pl-4" align="left">
                                                    {customer.name}
                                                </td>
                                                <td className="p-2" align="center">
                                                    {customer.openCases > 0 && (
                                                        <span className="badge badge-danger">
                                                            {customer.openCases} Open
                                                        </span>
                                                    )}
                                                </td>
                                                <td className="p-2" align="center">
                                                    {customer.closedCases > 0 && (
                                                        <span className="badge">{customer.closedCases} Closed</span>
                                                    )}
                                                </td>
                                                <td className="p-2" align="center">
                                                    {customer.tenants.length && (
                                                        <a className="underline text-blue-400" href="#">
                                                            {customer.tenants.length} Tenants
                                                        </a>
                                                    )}
                                                </td>
                                                <td className="p-2" align="right">
                                                    <a className="btn inline-flex rounded-md" href="#">
                                                        <span className="text-sm">View in Hubspot</span>
                                                        <ExternalLinkIcon className="ml-2 w-4" />
                                                    </a>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </section>
                    </div>
                </section>
                <section>{isTenantModalOpen && <CustomerTenantsModal isOpen={setIsTenantModalOpen} />}</section>
            </div>
        </>
    );
}
export default Customers;
