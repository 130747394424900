import { gql } from '@apollo/client';

export const CREATE_PROVIDER = gql`
    mutation createProvider($input: ProviderInput!) {
        createProvider(input: $input) {
            authHeader
            url
            provider {
                providerId
                type
                name
            }
        }
    }
`;

export const DELETE_PROVIDER = gql`
    mutation deleteProvider($tenantId: ID!, $providerId: ID!) {
        deleteProvider(tenantId: $tenantId, providerId: $providerId)
    }
`;

export const LIST_PROVIDERS = gql`
    query listProviders($tenantId: ID!) {
        listProviders(tenantId: $tenantId) {
            providerId
            type
            name
            status
            firstEventAt
            lastEventAt
            storageUsed
            lastError
            lastErrorAt
        }
    }
`;

export const LIST_TENANTS = gql`
    query listTenants {
        listTenants {
            tenantId
            name
            slug
            firstEventAt
            lastEventAt
            storageUsed
        }
    }
`;

export const GET_TENANT = gql`
    query getTenant($tenantId: ID!) {
        getTenant(tenantId: $tenantId) {
            tenantId
            name
            slug
            firstEventAt
            lastEventAt
            storageUsed
        }
    }
`;

export const ONBOARD_TENANT = gql`
    mutation onboardTenant($input: OnboardInput!) {
        onboardTenant(input: $input) {
            orgId
            tenantId
            result
        }
    }
`;

export const DELETE_TENANT = gql`
    mutation deleteTenant($tenantId: ID!) {
        deleteTenant(tenantId: $tenantId)
    }
`;

export const GET_IDENTITY_MAP = gql`
    query getIdentityMap($tenantId: ID!, $startTime: Int64!, $endTime: Int64!, $excludeTags: [IdentityMapFilterTags]) {
        getIdentityMap(tenantId: $tenantId, startTime: $startTime, endTime: $endTime, excludeTags: $excludeTags) {
            nodes {
                nodeId
                label
                props
                tags
            }
            edges {
                edgeId
                label
                inV
                outV
                tags
                props
            }
        }
    }
`;

export const CREATE_USER = gql`
    mutation createUser($input: UserInput!) {
        createUser(input: $input) {
            name
            email
            userId
            roleIds
        }
    }
`;

export const LIST_USERS = gql`
    query listUsers($tenantId: ID!) {
        listUsers(tenantId: $tenantId) {
            userId
            name
            email
            roleIds
        }
    }
`;

export const DELETE_USER = gql`
    mutation deleteUser($tenantId: ID!, $userId: ID!) {
        deleteUser(tenantId: $tenantId, userId: $userId)
    }
`;

export const GET_PROVIDER_FIELD_MAP = gql`
    query getProviderFieldMap($tenantId: ID!) {
        getProviderFieldMap(tenantId: $tenantId) {
            Name
            DisplayName
            Fields {
                Name
                DisplayName
                InputName
                Generated
                Mandatory
                RegexValidation
            }
            Hook
            HookAuthorizationKey
        }
    }
`;

export const LIST_ROLES = gql`
    query listRoles($tenantId: ID!) {
        listRoles(tenantId: $tenantId) {
            roleId
            tenantId
            name
        }
    }
`;

export const RESET_PASSWORD = gql`
    mutation resetPassword($tenantId: ID!, $userId: ID!) {
        generatePasswordReset(tenantId: $tenantId, userId: $userId)
    }
`;
