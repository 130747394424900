import production from 'Assets/logo-192x192.png';
import staging from 'Assets/logo-staging-192x192.png';
import development from 'Assets/logo-dev-192x192.png';
import { useEnvironment } from 'Hooks/Hooks';
import { NavLink } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ApolloClientContext } from 'Utilities/AuthorizedApolloProvider';
import { useContext, useMemo } from 'react';
import Select from 'react-select';

export const Header = (): JSX.Element => {
    return (
        <header className="text-gray-400 bg-gray-900 body-font fixed w-full z-50">
            <div className="container mx-auto flex flex-wrap p-3 flex-col md:flex-row items-center text-sm">
                <NavLink className="flex title-font font-medium items-center text-white mb-5 md:mb-0" to="/">
                    <Logo />
                    <div className="ml-3 flex flex-col justify-center leading-tight">
                        <span className="text-xl leading-tight">Operations</span>
                        <Environment />
                    </div>
                </NavLink>
                <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-700 flex flex-wrap items-center">
                    {/* <NavLink to="/customers" className="mr-5 hover:text-white focus:outline-none">
                        Customers
                    </NavLink> */}
                    <NavLink to="/tenants" className="mr-5 hover:text-white focus:outline-none">
                        Tenants
                    </NavLink>
                    {/* <NavLink to="/features" className="mr-5 hover:text-white focus:outline-none">
                        Features
                    </NavLink>
                    <NavLink to="/auth0" className="mr-5 hover:text-white focus:outline-none">
                        Auth0
                    </NavLink> */}
                </nav>
                <div className="inline-flex items-center justify-center">
                    <ClusterSelector />
                </div>
            </div>
        </header>
    );
};

const Logo = (): JSX.Element => {
    const env = useEnvironment();

    return (
        <>
            {env == 'dev' && <img src={development} alt="double zero logo" className="h-10" />}
            {env == 'staging' && <img src={staging} alt="double zero logo" className="h-10" />}
            {env == 'prod' && <img src={production} alt="double zero logo" className="h-10" />}
        </>
    );
};

const Environment = (): JSX.Element => {
    const env = useEnvironment();

    return (
        <>
            {env == 'dev' && (
                <p className="text-[10px] leading-normal uppercase font-bold tracking-widest text-gray-400">
                    Development
                </p>
            )}
            {env == 'staging' && (
                <p className="text-[10px] leading-normal uppercase font-bold tracking-widest text-gray-400">Staging</p>
            )}
        </>
    );
};

const ClusterSelector = (): JSX.Element => {
    const { availableClusters } = useFlags();

    const { graphqlUri, setGraphqlUri } = useContext(ApolloClientContext);

    const availableClusterOptions: { label: string; value: string }[] = useMemo(() => {
        const options = availableClusters.map(({ name, url }: { name: string; url: string }) => ({
            label: `${name} - ${url}`,
            value: url,
        }));

        console.log('availableClusterOptions', options);

        return options;
    }, [availableClusters]);

    const handleClusterChange = (option: { label: string; value: string }) => {
        const cluster = option.value;

        console.log(`Setting cluster from ${graphqlUri} to ${cluster}`);

        setGraphqlUri(cluster);
    };

    return (
        <Select
            defaultValue={availableClusterOptions[0]}
            onChange={handleClusterChange}
            classNamePrefix="select-xs"
            className="w-128"
            options={availableClusterOptions}
        />
    );
};
