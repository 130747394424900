import React, { createContext, useMemo, useRef, useState } from 'react';
import { ApolloProvider, ApolloClient, InMemoryCache, HttpLink, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/link-context';
import { useAuth0 } from '@auth0/auth0-react';
import jwtDecode from 'jwt-decode';
import { JwtPayload } from 'Types/types';

type ApolloClientContextProps = {
    graphqlUri: string | undefined;
    setGraphqlUri: (uri: string) => void;
};

export const ApolloClientContext = createContext<ApolloClientContextProps>({
    graphqlUri: undefined,
    setGraphqlUri: () => undefined,
});

const AuthorizedApolloProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
    const [graphqlUri, setGraphqlUri] = useState<string | undefined>(window.__env__.GRAPHQL_URI);

    const context = React.useMemo(() => ({ graphqlUri, setGraphqlUri }), [graphqlUri, setGraphqlUri]);

    const { getAccessTokenSilently } = useAuth0();

    const client = useRef<ApolloClient<NormalizedCacheObject> | undefined>();

    client.current = useMemo(() => {
        console.log('AuthorizedApolloProvider: graphqlUri', graphqlUri);

        const graphql_uri = graphqlUri;
        if (!graphql_uri) {
            throw new Error('GRAPHQL_URI runtime variable is not set');
        }
        const httpLink = new HttpLink({
            uri: graphql_uri,
        });

        const authLink = setContext(async (_, { headers, ...rest }) => {
            let token;
            try {
                token = await getAccessTokenSilently();
                if (token) {
                    localStorage.setItem('accessToken', token);
                }

                // There should be a tenantId in the JWT token
                // the tenantId is used when making various API calls.
                //
                // If the tenantId is not set, this usually means the user is not
                // bound to the tenant correctly in auth0
                const decoded_token = jwtDecode<JwtPayload>(token);
                const tenantId = decoded_token['https://doublezero.io/tenant'];
                if (tenantId) {
                    localStorage.setItem('tenantId', tenantId);
                }
            } catch (error) {
                console.log(error);
            }

            if (!token) return { headers, ...rest };

            return {
                ...rest,
                headers: {
                    ...headers,
                    authorization: `Bearer ${token}`,
                },
            };
        });

        return new ApolloClient({
            link: authLink.concat(httpLink),
            cache: new InMemoryCache({
                typePolicies: {
                    User: {
                        keyFields: ['userId'],
                    },
                    Provider: {
                        keyFields: ['providerId'],
                    },
                },
            }),
        });
    }, [getAccessTokenSilently, graphqlUri]);

    return (
        <ApolloClientContext.Provider value={context}>
            {client.current && <ApolloProvider client={client.current}>{children}</ApolloProvider>}
        </ApolloClientContext.Provider>
    );
};

export default AuthorizedApolloProvider;
