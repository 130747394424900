import { useQuery } from '@apollo/client';
import { LIST_ROLES } from '../../../Graph/queries';

type Role = {
    roleId: string;
    name: string;
    tenantId: string;
};

interface Props {
    tenantId: string;
}

export const Roles = ({ tenantId }: Props): JSX.Element => {
    const { loading, error, data } = useQuery(LIST_ROLES, { variables: { tenantId } });

    let content = <></>;

    const transformName = (uglyName: string) => {
        const parts = uglyName.split(':');
        return parts[1];
    };

    if (loading) {
        content = (
            <tr>
                <td colSpan={2} className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500 flex">
                    <div className="text-sm text-gray-500 justify-center flex items-center">
                        <div className="loader mr-2" />
                        Loading Roles...
                    </div>
                </td>
            </tr>
        );
    } else if (error) {
        content = (
            <tr>
                <td colSpan={2} className="px-6 py-4 whitespace-nowrap text-sm font-medium text-red-500">
                    Could not load Roles for this Tenant. Please retry later.
                </td>
            </tr>
        );
    } else if (data) {
        content = data.listRoles.map((role: Role) => (
            <tr key={role.roleId}>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white" align="left">
                    {transformName(role.name)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-400" align="left">
                    {role.roleId}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white" align="right"></td>
            </tr>
        ));
    }

    return (
        <>
            <div className="flex items-center justify-between mb-5 h-[32px]">
                <span className="text-sm">Viewing {data.listRoles.length} roles</span>
            </div>
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-700 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-700">
                            <thead className="bg-gray-700">
                                <tr className="text-sm">
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                                        align="left"
                                    >
                                        Role
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                                        align="left"
                                    >
                                        UUID
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                                        align="right"
                                    ></th>
                                </tr>
                            </thead>
                            <tbody className="bg-gray-800 divide-y divide-gray-700">{content}</tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};
