// React Dependencies
import { Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

// Higher Order Components for services: Auth0, LaunchDarkly
import ProtectedRoute from 'Utilities/ProtectedRoute';
import Auth0ProviderWithHistory from 'Utilities/Auth0ProviderWithHistory';
import LaunchDarklyProvider from 'Utilities/LaunchDarklyProvider';

// Ops components
import AuthorizedApolloProvider from './Utilities/AuthorizedApolloProvider';
import Customers from './Views/Mocks/Customers';
import TenantList from './Views/TenantList/TenantList';
import TenantDetails from './Views/Tenant/Tenant';
import Features from './Views/Mocks/Features';
import Auth0 from './Views/Mocks/Auth0';
import { HelmetProvider } from 'react-helmet-async';
import { Header } from 'Views/Layout/Header';
import Favicon from 'Utilities/Favicon';
import { Footer } from 'Views/Layout/Footer';

// History initialization
export const history = createBrowserHistory();

// Create and serve the Double Zero application
function App(): JSX.Element {
    return (
        <LaunchDarklyProvider>
            <HelmetProvider>
                <Favicon />
                <Auth0ProviderWithHistory>
                    <AuthorizedApolloProvider>
                        <Router history={history}>
                            <Header />
                            <div className="py-16">
                                <Switch>
                                    <ProtectedRoute path="/tenants/:tenantId" component={TenantDetails} />
                                    <ProtectedRoute path="/customers" component={Customers} />
                                    <ProtectedRoute path="/tenants" component={TenantList} />
                                    <ProtectedRoute path="/features" component={Features} />
                                    <ProtectedRoute path="/auth0" component={Auth0} />
                                    <ProtectedRoute path="/" component={TenantList} />
                                </Switch>
                            </div>
                            <Footer />
                        </Router>
                    </AuthorizedApolloProvider>
                </Auth0ProviderWithHistory>
            </HelmetProvider>
        </LaunchDarklyProvider>
    );
}

export default App;
