import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { TrashIcon, UserAddIcon } from '@heroicons/react/solid';
import { LIST_USERS, DELETE_USER, LIST_ROLES } from 'Graph/queries';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { transformName } from 'Utilities/utils';
import { CreateUser } from './CreateUser';

type User = {
    name: string;
    email: string;
    userId: string;
    roleIds?: string[];
};

export const Users = (): JSX.Element => {
    const { tenantId } = useParams<{ tenantId: string }>();
    const client = useApolloClient();
    const { loading, error, data } = useQuery(LIST_USERS, { variables: { tenantId } });
    const { data: roleData } = useQuery(LIST_ROLES, { variables: { tenantId } });

    const [deleteUser] = useMutation(DELETE_USER, {
        refetchQueries: [{ query: LIST_USERS, variables: { tenantId } }],
    });

    const [openModal, setOpenModal] = useState(false);
    const [errorDeletingUser, setErrorDeletingUser] = useState('');

    const roles = useMemo(() => {
        const tempRoles: Record<string, string> = {};
        if (roleData) {
            roleData.listRoles.map((role: { roleId: string; name: string }) => (tempRoles[role.roleId] = role.name));
        }
        return tempRoles;
    }, [roleData]);

    const renderRoles = (roleIds: string[]) => {
        const finishedRoles = roleIds.map((roleId) => {
            const roleName = roles[roleId];
            if (roleName) {
                return transformName(roleName);
            } else return `unnamed role (${roleId})`;
        });
        return finishedRoles.join(', ');
    };

    const deleteThisUser = async (user: User) => {
        try {
            const userId = user.userId;
            await deleteUser({ variables: { tenantId, userId } });

            const users = client.readQuery({ query: LIST_USERS, variables: { tenantId } });
            const newUsers = users.listUsers.filter((user: User) => user.userId !== userId);
            client.writeQuery({
                query: LIST_USERS,
                variables: { tenantId },
                data: { listUsers: newUsers },
            });
        } catch (e) {
            setErrorDeletingUser('Error deleting user');
        }
    };

    let content = <></>;

    if (loading) {
        content = (
            <div className="text-sm text-gray-500 justify-center flex items-center p-3">
                <div className="loader mr-2" />
                Loading Users...
            </div>
        );
    } else if (error) {
        content = (
            <p className="text-sm text-red-500 text-center">
                Could not load Users for this Tenant. Please retry later.
            </p>
        );
    } else if (data) {
        content = (
            <>
                <div className="flex items-center justify-between mb-5">
                    <span className="text-sm">Viewing {data.listUsers.length} users</span>
                    <button
                        type="button"
                        className="btn inline-flex rounded-md text-xs"
                        onClick={() => setOpenModal(true)}
                    >
                        <UserAddIcon className="h-4 w-4 mr-2" />
                        <span>Add New User</span>
                    </button>
                </div>
                {errorDeletingUser && <p className="text-sm text-red-600 px-3 mb-5">{errorDeletingUser}</p>}
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-700 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-700">
                                <thead className="bg-gray-700">
                                    <tr className="text-sm">
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                                            align="left"
                                        >
                                            Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                                            align="left"
                                        >
                                            Email Address
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                                            align="left"
                                        >
                                            UUID
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                                            align="left"
                                        >
                                            Roles
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                                            align="right"
                                        ></th>
                                    </tr>
                                </thead>
                                <tbody className="bg-gray-800 divide-y divide-gray-700">
                                    {data.listUsers.map((user: User) => (
                                        <tr key={user.userId}>
                                            <td
                                                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"
                                                align="left"
                                            >
                                                {user.name}
                                            </td>
                                            <td
                                                className="px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-400"
                                                align="left"
                                            >
                                                {user.email}
                                            </td>
                                            <td
                                                className="px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-400"
                                                align="left"
                                            >
                                                {user.userId}
                                            </td>
                                            <td
                                                className="px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-400"
                                                align="left"
                                            >
                                                {user.roleIds && renderRoles(user.roleIds)}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap" align="right">
                                                <div
                                                    className="flex justify-end items-center text-red-500 cursor-pointer"
                                                    onClick={() => deleteThisUser(user)}
                                                >
                                                    <TrashIcon className="h-4 w-4 mr-2" />
                                                    <span className="text-sm">Delete</span>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {openModal && <CreateUser setOpenModal={setOpenModal} />}
            </>
        );
    }

    return <>{content}</>;
};
